$(document).ready(function($) {

    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5&appId=1588138698090239";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));

    if ($("#carousel").length > 0) {
        $("#carousel").owlCarousel({
            autoPlay: 5000,
            items: 1,
            theme: "owl-theme",
            itemsMobile: [479, 1],
            itemsTablet: [768, 1],
            itemsDesktopSmall: [979, 1],
            itemsDesktop: [1700, 1]
        });
    }

    if ($("#testimonial-carousel").length > 0) {
        $("#testimonial-carousel").owlCarousel({
            nav: true,
            loop: true,
            autoPlay: 5000,
            items: 1,
            navText: ["Prev", "Next"],
            theme: "owl-theme",
            itemsMobile: [479, 1],
            itemsTablet: [768, 1],
            itemsDesktopSmall: [979, 1],
            itemsDesktop: [1700, 1]
        });
    }

    /**
     * Disable Sign up form from refreshing page
     */
    $("#cm_thanks").submit(function() {
        return false;
    });

    // Header drop down hover
    $(".main-header .dropdown").hover(
        function() {
            $(".dropdown-menu", this).stop().fadeIn("fast");
            $(this).siblings().addClass("fade");
        },
        function() {
            $(".dropdown-menu", this).stop().fadeOut("fast");
            $(this).siblings().removeClass("fade");
        }
    );

    // Header Search Box
    $(".navbar-search .search").click(function() {
        $(".navbar-search-form input").toggleClass("active");
        $(".navbar-search .search").toggleClass("active");
        $(".navbar-search-form input").focus();
    });

    // Smoothie Layout
    var $smoothieGrid = $(".smoothies .grid").isotope({
        itemSelector: ".smoothie-wrapper",
        layoutMode: "fitRows",
        columnWidth: ".smoothie-sizer",
        percentPosition: true
    });

    // layout Isotope after each image loads
    $smoothieGrid.imagesLoaded().progress(function() {
        $smoothieGrid.isotope("layout");
    });

    // Smoothie Filters
    $(".smoothie-filters button").on("click", function() {
        var filterValue = $(this).attr("data-filter");
        $smoothieGrid.isotope({ filter: filterValue });
    });

    // Smoothie Filters change active class on buttons
    $(".smoothie-filters button").each(function(i, button) {
        var $button = $(button);
        $button.on("click", function() {
            $(".smoothie-filters").find(".active").removeClass("active");
            $(this).addClass("active");
        });
    });

    // Smoothie Filters from another Page
    if ($(".smoothielisting-body").length > 0) {
        var filter = getParameterByName("filter");
        if (filter.length > 0) {
            $(".smoothie-filters ." + filter).click();
        }
    }

    // Ingredients Sub Headings
    var $ingredientSubheadings = $(".ingredients-wrapper .subheading");
    if ($ingredientSubheadings.length > 0) {
        for (var i = 0; i < $ingredientSubheadings.length; i++) {
            $($ingredientSubheadings[i]).parent().addClass("has-sub");
        }
    }

    // Mobile SlideOut Menu
    var slideout = new Slideout({
        "panel": document.getElementById("panel"),
        "menu": document.getElementById("mobile-menu"),
        "side": "right",
        "padding": 767
    });
    slideout.disableTouch();;

    // Toggle button
    document.querySelector(".toggle-button").addEventListener("click", function() {
        slideout.toggle();
        toggleMenuClass();
    });

    createMobileMenu();

    $("#locationSelect").on("change", function() {
        var e = $("#locationSelect")[0];
        setCookie(e.value);
        window.location.replace(window.location.origin + window.location.pathname);
    });

    $("#locationSelect").val(getLocationCookie());

    $("#mobile-filter-select").click(function() {
        $(".product-category-listing-wrapper").toggleClass("open");
        $("#mobile-filter-select .fa-sort-desc").toggleClass("open");
    });

    $("#mobile-filter-select-smoothies").click(function() {
        $(".filters-group").toggleClass("open");
        $("#mobile-filter-select-smoothies .fa-sort-desc").toggleClass("open");
    });

    // Recipe advanced search cancel closes tab
    $(".advanced-search .cancel").click(function() {
        $(".advanced-search .collapse").collapse("hide");
    });

    $(".popup_video").each(function(index) {
        $(this).iLightBox();
    });

    if ($(".genericpage-body").length > 0) {
        if ($("#panel iframe.landing-iframe").length > 0) {
            $("#panel .content").css("padding", "0");
        }
    }

    // Update modal country
    $("#international-modal")
        .on("show.bs.modal",
            function (event) {
                var country = $("#user-country").val();
                console.log(country);

                var modal = $(this);
                modal.find("#modal-country").text(country);
            });
});

/**
 * Toggle Mobile Menu Icon
 */
function toggleMenuClass() {
    var $icon = $(".toggle-button").find("i");
    if ($icon.hasClass("fa-bars")) {
        $icon.removeClass("fa-bars");
        $icon.addClass("fa-times");
    } else {
        $icon.removeClass("fa-times");
        $icon.addClass("fa-bars");
    }
}

/**
 * Get Query String Param
 * @param {string} name
 * @returns {string}
 */
function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);
    if (results == null)
        return "";
    else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
}

// Mobile Menu Duplicate Menu
function createMobileMenu() {
    var $mobileMenu = $("#mobile-menu");
    var $menu = $("#navbar").clone();
    $mobileMenu.append($menu);
    $mobileMenu.find("#navbar").removeClass("collapse");
    $mobileMenu.find(".navbar-search").remove();
    var $mobileSearch = $mobileMenu.find(".navbar-search-form");
    $mobileSearch.find("input").removeClass("active");
    $mobileMenu.find("#navbar").prepend($mobileSearch);
    $mobileSearch.append('<div class="input-group search-bar"></div>');
    $mobileSearch.find(".search-bar").append('<span class="input-group-addon search-icon" id="search-addon"><i class="fa fa-search"></i></span>');
    $mobileSearch.find(".search-bar").append($mobileSearch.find("input"));
    $mobileSearch.find("input").attr("placeholder", "Search Pauls...");
}

function setCookie(newCookieLocation) {
    var d = new Date();
    d.setTime(d.getTime() + (48 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = "location=" + newCookieLocation + ";" + expires + ";" + "path=/;";

}

function getLocationCookie() {
    var cname = "location";
    var name = cname + "=";
    var cookieValue = "";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1);
        if (c.indexOf(name) == 0) cookieValue = c.substring(name.length, c.length);
    }
    if (cookieValue == "") {
        $.getJSON(
            "/Umbraco/Api/Location/GetLocation?ip=" + myip, // be sure to add the callback to the URL
            {},
            function(data, status) {
                if (data.Status === 400) {

                } else { // 200
                    $("#locationSelect").val(data);
                    setCookie(data);
                }
            });
    } else {
        return cookieValue;
    }
}

function deleteCookie() {
    document.cookie = "location" + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function subscribeUser() {
    if (!$("#cm_thanks")[0].checkValidity()) {
        if (Modernizr.input.required && Modernizr.formvalidation) {
            return false;
        } else {
            if (document.getElementById("cm_thanks").elements["cm-name"].value === "") {
                window.alert("Please enter your name.");
            } else if (document.getElementById("cm_thanks").elements["fieldEmail"].value === "") {
                window.alert("Please enter your email address.");
            } else if (!document.getElementById("cm_thanks").elements["privacy"].checked) {
                window.alert("Please accept terms and conditions.");
            }
            return false;
        }
    }
    // be sure to add the callback to the URL
    $.getJSON(
        "http://parmalataustralia1.createsend.com/t/i/s/huhuly/?callback=?",
        {
            "cm-name": document.getElementById("fieldName").value,
            "cm-huhuly-huhuly": document.getElementById("fieldEmail").value
        },
        function(data, status) {
            console.log(data, status);
            if (data.Status === 400) {
                document.getElementById("cm_thanks").innerHTML = "<h2>Sorry:</h2><h2>We had an error while trying to store your data, please try again later.</h2>";
                ga('send', 'event', 'Campaign Monitor Signup', 'Fail', data.Status);
            } else { // 200
                document.getElementById("cm_thanks").innerHTML = '<img src="/media/static/icons/general/mail-white.png" alt="mail"><h2>Thanks for subscribing!</h2><h2>Keep an eye out for our next newsletter</h2>';
                ga('send', 'event', 'Campaign Monitor Signup', 'Success', 'N/A');
            }
        });
}

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}
