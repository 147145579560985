// Recipe Page Advanced Print
$(document)
    .ready(function($) {
        var advancedPrintOptions = ["ingredients", "method", "image"];
        $("#advancedPrint .dropdown-menu a")
            .click(function(event) {
                event.preventDefault();

                if ($(this).hasClass("print")) {
                    // Ingredients
                    if (advancedPrintOptions.indexOf("ingredients") < 0) {
                        $("#ingredients").addClass("hidden-print");
                    } else {
                        $("#ingredients").removeClass("hidden-print");
                    }

                    // Method
                    if (advancedPrintOptions.indexOf("method") < 0) {
                        $("#method").addClass("hidden-print");
                    } else {
                        $("#method").removeClass("hidden-print");
                    }

                    // Image
                    if (advancedPrintOptions.indexOf("image") < 0) {
                        $("#image").addClass("hidden-print");
                    } else {
                        $("#image").removeClass("hidden-print");
                    }

                    window.print();
                    return true;
                } else {
                    console.log("click");
                    var $target = $(event.currentTarget);
                    var val = $target.attr("data-value");
                    var $inp = $target.find("input");
                    var idx;

                    if ((idx = advancedPrintOptions.indexOf(val)) > -1) {
                        advancedPrintOptions.splice(idx, 1);
                        setTimeout(function() { $inp.prop("checked", false) }, 0);
                    } else {
                        advancedPrintOptions.push(val);
                        setTimeout(function() { $inp.prop("checked", true) }, 0);
                    }

                    $(event.target).blur();
                    console.log(advancedPrintOptions);

                    return false;
                }
            });
    });
