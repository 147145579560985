// Contact Page
$(document).ready(function($) {
    if ($(".contactpage-body").length > 0) {
        // Check if complaint query string present.
        var type = getUrlVars()["type"];
        if (type) {
            if (type === "complaint") {
                // Enquiry type select box
                $("#17a6dd67-ae3e-41b2-c701-b1e8f6dc4f8d").val("Product complaint");
                $(".contourField.productname").show();
                $(".contourField.productsize").show();
                $(".contourField.usebydate").show();
                $(".contourField.batchcode").show();
            }
        }
    }
});


// Read a page's GET URL variables and return them as an associative array.
function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}
