$(document)
    .ready(function($) {
        $(".askpauls-body .contact-form textarea").attr("placeholder", "My question is...");
        $(".askpauls-body .contact-form .text").attr("placeholder", "Email address");
        $(".askpauls-body .contact-form .btn").attr("value", "Send Question to Pauls");
        $(".askpauls-body .contact-form textarea")
            .keyup(function(e) {
                clearTimeout($.data(this, "timer"));
                if (e.keyCode === 13) {
                    searchAskPauls(true);
                } else {
                    $(this).data("timer", setTimeout(searchAskPauls, 100));
                }
            });
        $(".askpauls-body .contact-form .btn-close")
            .click(function(e) {
                var askedQ = $(".askpauls-body .contact-form textarea").val();
                e.preventDefault();
                trackAskPauls("not-really", askedQ);
                $(".askpauls-body .contact-results").addClass("hidden");
            });

        $(".askpauls-body .btn-response").click(function() {
            toggleResponseButton(this);
        });

        $(".askpauls-body .load-more")
            .click(function() {
                var activeCat = getActiveCategoryID();
                var activeQuestions = getCurrentQuestions();
                getMoreQuestions(activeCat, activeQuestions);
                // Append to current questions
                // If no questions hide button
            });

        $(".askpauls-body .categories a")
            .click(function(e) {
                e.preventDefault();
                $(".askpauls-body .load-more-wrapper").show();
                $(".askpauls-body .categories .active").removeClass("active");
                $(this).parent().toggleClass("active");
                $(".askpauls-body .questions .question").remove();
                var activeCat = getActiveCategoryID();
                var activeQuestions = getCurrentQuestions();
                getMoreQuestions(activeCat, activeQuestions);
            });

        prettyResponseBox();
    });

function toggleResponseButton(button) {
    $(button).toggleClass("close");
    if ($(button).hasClass("close")) {
        button.innerHTML = "<span>+</span> Hide response";
    } else {
        button.innerHTML = "<span>+</span> Show response";
    }
}

/**
 * Get current active category id
 * @returns {string}
 */
function getActiveCategoryID() {
    var $active = $(".askpauls-body .categories .active a");
    return $active.attr("data-category-id");
}

/**
 * Get current question ids
 * @returns {Array<string>}
 */
function getCurrentQuestions() {
    var ids = [];
    var $questions = $(".askpauls-body .questions .question");
    for (var i = 0; i < $questions.length; i++) {
        ids.push($($(".askpauls-body .questions .question")[i]).attr("data-question-id"));
    }

    return ids;
}

/**
 * Get More Questions
 * @param {string} category
 * @param {Array} currentQuestions
 */
function getMoreQuestions(category, currentQuestions) {
    $.ajax({
        type: "GET",
        url: "/Umbraco/Api/Questions/GetMoreQuestions?cat=" + category + "&qs=" + currentQuestions,
        success: function(data) {
            appendMoreQuestions(data);
        },
        error: function() {
            console.log("Request failed");
        }
    });
}

function appendMoreQuestions(questions) {
    questions = JSON.parse(questions);
    if (questions.length === 0) {
        $(".askpauls-body .load-more-wrapper").hide();
        return;
    }

    for (var i = 0; i < questions.length; i++) {
        appendQuestion(questions[i]);
    }
}

function appendQuestion(question) {
    var $qObj = $("" +
    '<div class="question" data-question-id="' + question.Id + '">' +
        "<p>" + question.Question +
            '<button class="btn btn-primary btn-response" type="button" ' +
                'data-toggle="collapse" ' +
                'data-target="#response-' + question.Id + '" ' +
                'aria-expanded="false" ' +
                'aria-controls="response-' + question.Id + '">' +
                "<span>+</span> Show response" +
            "</button>" +
        "</p>" +
        '<div id="response-' + question.Id + '" class="collapse">' +
            '<div class="well response">' +
                "<p>" + question.Response + "</p>" +
            "</div>" +
        "</div>" +
    "</div>");
    var $last = $(".askpauls-body .questions .question").last();
    if ($last.length === 0) {
        $($qObj).insertBefore(".askpauls-body .load-more-wrapper");
    } else {
        $($qObj).insertAfter($last);
    }
    $($qObj)
        .find(".btn-response")
        .click(function() {
            toggleResponseButton(this);
        });
}

/**
 * Search Ask Pauls Questions
 * @param {boolean} force
 */
function searchAskPauls(force) {
    var existingString = $(".askpauls-body .contact-form textarea").val();
    if (!force && existingString.length < 3) {
        $("#askpauls-results").addClass("hidden");
        return;
    };

    $.ajax({
        type: "GET",
        url: "/Umbraco/Api/Questions/GetExamples?search=" + existingString,
        success: function(data) {
            processAskPaulsResponse(data);
        },
        error: function() {
            console.log("Request failed");
        }
    });
}

/**
 * Process Ask Pauls question responses.
 * @param {string} data
 */
function processAskPaulsResponse(data) {
    removeAskPaulsResponses();
    data = JSON.parse(data);
    if (data.length > 0) {
        $("#askpauls-results").removeClass("hidden");
        for (var i = 0; i < data.length; i++) {
            addAskPaulsResponse(data[i]);
        }
    } else {
        var contactResponse = {
            Question: "Can't find what you're looking for? Contact us <a id='contact-link' href='/contact/'>here</a>.",
            Id: 0,
            Response: null
        }
        $("#askpauls-results").removeClass("hidden");
        addAskPaulsResponse(contactResponse, true);
    }
}

function removeAskPaulsResponses() {
    $("#askpauls-results-q").children().remove();
}

/**
 * Add Ask Pauls Response
 * @param {Object} question
 * @param {Boolean} [contact] - Optional if response if contact form
 */
function addAskPaulsResponse(question, contact) {
    var response;
    if (contact) {
        response = "contact";
    } else {
        response = "response";
    }
    var askedQ = $(".askpauls-body .contact-form textarea").val();
    var $qObj;
    if (question.Response) {
        $qObj = $('<div class="question">' +
                    "<p>" + question.Question +
                        '<button class="btn btn-primary btn-response" type="button" ' +
                            'data-toggle="collapse" ' +
                            'data-target="#response-returned-' + question.Id + '" ' +
                            'aria-expanded="false" ' +
                            'aria-controls="response-returned-' + question.Id + '">' +
                            "<span>+</span> Show response" +
                        "</button>" +
                    "</p>" +
                    '<div id="response-returned-' + question.Id + '" class="collapse">' +
                        '<div class="well response">' +
                            "<p>" + question.Response + "</p>" +
                        "</div>" +
                    "</div>" +
                "</div>");
    } else {
        $qObj = $('<div class="question">' +
            "<p>" + question.Question + "</p>" +
        "</div>");
    }

    $("#askpauls-results-q").append($qObj);
    if (contact) {
        $($qObj).find(".btn-response").addClass("hidden");
        $($qObj).find("#contact-link")
            .click(function() {
                trackAskPauls(response, askedQ);
            });
    }
    $($qObj).find(".btn-response").click(function() {
        toggleResponseButton(this);
        if ($(this).hasClass("close")) {
            trackAskPauls(response, askedQ);
        }
    });
}

function prettyResponseBox() {
    if ($(".contourPage").length === 0 && $(".askpauls-body").length > 0) {
        $(".ask-contact-overlay").hide();
        $(".email-overlay").hide();
    }
}

/**
 * Google Analytics Event Tracking
 * @param {string} event
 * @param {string} question
 */
function trackAskPauls(event, question) {
    console.log("track");
    console.log(event, question);
    // ReSharper disable once UseOfImplicitGlobalInFunctionScope
    ga("send", "event", "Ask Pauls", event, question);
}
